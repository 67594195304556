@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
    /* width: 100%; */
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.Stat-alert {
  animation: Stat-alert-blink infinite 1s linear;
}

@keyframes Stat-alert-blink {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}
